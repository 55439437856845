.social-icon {
  padding: 2px;
}

.entry {
  font-size: 9px;
  color: black
}

.entry-disabled {
  color: #CCC;
}

.entry-list {
  column-count: 6;
  column-width: 120px;
}
